
  import { defineComponent, reactive, toRefs, onBeforeMount, computed, ref } from 'vue';
  import { useToast } from "vue-toastification";
  import { useRoute } from 'vue-router';

  import ActivityForm from "./components/ActivityForm.vue";
  import AppButton from "@/components/stateless/AppButton.vue";
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';
  import UnsavedChangesModal from '@/components/UnsavedChangesModal.vue';

  import { IActivity, TIndexedObject, IActivityForm } from '@/types';
  import { vuex } from "@/store";
  import { router, routesNames } from '@/router';
  import { activitiesService } from "@/services";
  import { getChangedData, capitalizeFirstLetter, downloadHelper } from '@/core/helper-functions';
  import { ACTIVITY_PARAMS } from '@/views/admin/activities/api-params';
  
  export default defineComponent({
    name: 'UpdateActivity',

    components: { ActivityForm, AppButton, AppTruncatedTooltip, UnsavedChangesModal },

    setup() {
      const toast = useToast();
      const route = useRoute();
      const state = reactive({
        originalActivity: {} as Partial<IActivityForm>,
        editActivityForm: {} as Partial<IActivityForm>
      });

      const typesList = [
        { value: 'child', label: 'Child' },
        { value: 'adult', label: 'Adult' }
      ] as TIndexedObject[];

      const { editActivityForm, originalActivity } = toRefs(state);
      const disabledHandleChanges = ref<boolean>(false);

      const changedData = computed<Partial<IActivityForm>>(() => {
        return getChangedData(originalActivity.value, editActivityForm.value);
      });

      const dataForUpdate = computed<Partial<IActivityForm>>(() => {
        let data = { ...changedData.value } as Partial<IActivityForm>;

        // Check if one of the dates changed and send builk of them
        if (isDateChanged()) {
          data = {
            ...data,
            startDate: editActivityForm.value.startDate,
            endDate: editActivityForm.value.endDate,
          };
        }

        // Check if property type exist and pass just value
        if (changedData.value.type) {
          return {
            ...data,
            type: changedData.value.type.value
          };
        }

        return data;
      });

      const disabledAction = computed<boolean>(() => !Object.keys(changedData.value).length);

      function isDateChanged() {
        const dateKaysList = ['startDate', 'endDate'];
        const changedDataKeys = Object.keys(changedData.value);
        let result = false;

        dateKaysList.forEach((el: string) => {
          if (changedDataKeys.includes(el)) {
            result = true;
          }
        });

        return result;
      }

      async function onUpdate() {
        vuex.setLoading(true);

        return activitiesService.updateActivity(route.query.id as string, dataForUpdate.value)
          .then(() => {
            disabledHandleChanges.value = true;
            toast.success('Activity has been updated');
            router.push({ name: routesNames.adminActivities });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function fetchInitData() {
        if (!route.query.id) {
          router.push({ name: routesNames.adminActivities });
        } else {
          await fetchActivity();
        }
      }

      function setActivityForms(activity: IActivity) {
        editActivityForm.value = {
          ...activity,
          type: { value: activity.type, label: capitalizeFirstLetter(activity.type) }
        };
        originalActivity.value = { ...editActivityForm.value };
      }

      async function fetchActivity() {
        vuex.setLoading(true);

        return activitiesService.fetchActivity(route.query.id as string, ACTIVITY_PARAMS)
          .then((activity: IActivity) => setActivityForms(activity))
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            router.push({ name: routesNames.adminActivities });
          })
          .finally(() => vuex.setLoading(false));
      }

      async function onExport() {
        vuex.setLoading(true);

        return activitiesService.downloadAttendees(route.query.id as string)
          .then((res: any) => {
            downloadHelper(res, originalActivity.value.name as string, 'csv');
          } )
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(() => { fetchInitData(); });

      return {
        typesList,
        editActivityForm,
        disabledHandleChanges,

        disabledAction,

        onUpdate,
        onExport
      };
    }

  });
