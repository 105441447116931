<template>
  <div
    v-if="editActivityForm.id"
    class="bg-white rounded-5 px-50 py-30 w-full h-full"
  >
    <ActivityForm
      :data="editActivityForm"
      :types-list="typesList"
      :disabled-action="disabledAction"
      mode="edit"
      @update="onUpdate"
    />

    <div class="px-25 mt-18">
      <p class="text-dark-cl-20 mb-8">Attendees</p>
      <div class="flex flex-start">
        <div>
          <AppButton 
            type="primary" 
            plain 
            class="px-30 py-15 mr-15" 
            :disabled="!editActivityForm.attendees || !editActivityForm.attendees.length"
            @click="onExport"
          >
            Export in CSV
          </AppButton>
        </div>
        <ul 
          v-if="editActivityForm.attendees && editActivityForm.attendees.length" 
          class="max-h-250 overflow-y-auto min-w-450 rounded border border-grey-fp-20"
        >
          <li 
            v-for="item of editActivityForm.attendees" 
            :key="item" 
            class="text-md py-13 px-25 truncate odd:bg-grey-fp-20"
          >
            <AppTruncatedTooltip 
              class="text-dark-cl-20" 
              :content="item.firstName + ' ' + item.lastName" 
            />
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- U N S A V E D   C H A N G E S   H A N D L E R  -->
  <UnsavedChangesModal v-if="editActivityForm.id" :disabled="disabledHandleChanges" :data="editActivityForm" />
</template>

<script lang="ts">
  import { defineComponent, reactive, toRefs, onBeforeMount, computed, ref } from 'vue';
  import { useToast } from "vue-toastification";
  import { useRoute } from 'vue-router';

  import ActivityForm from "./components/ActivityForm.vue";
  import AppButton from "@/components/stateless/AppButton.vue";
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';
  import UnsavedChangesModal from '@/components/UnsavedChangesModal.vue';

  import { IActivity, TIndexedObject, IActivityForm } from '@/types';
  import { vuex } from "@/store";
  import { router, routesNames } from '@/router';
  import { activitiesService } from "@/services";
  import { getChangedData, capitalizeFirstLetter, downloadHelper } from '@/core/helper-functions';
  import { ACTIVITY_PARAMS } from '@/views/admin/activities/api-params';
  
  export default defineComponent({
    name: 'UpdateActivity',

    components: { ActivityForm, AppButton, AppTruncatedTooltip, UnsavedChangesModal },

    setup() {
      const toast = useToast();
      const route = useRoute();
      const state = reactive({
        originalActivity: {} as Partial<IActivityForm>,
        editActivityForm: {} as Partial<IActivityForm>
      });

      const typesList = [
        { value: 'child', label: 'Child' },
        { value: 'adult', label: 'Adult' }
      ] as TIndexedObject[];

      const { editActivityForm, originalActivity } = toRefs(state);
      const disabledHandleChanges = ref<boolean>(false);

      const changedData = computed<Partial<IActivityForm>>(() => {
        return getChangedData(originalActivity.value, editActivityForm.value);
      });

      const dataForUpdate = computed<Partial<IActivityForm>>(() => {
        let data = { ...changedData.value } as Partial<IActivityForm>;

        // Check if one of the dates changed and send builk of them
        if (isDateChanged()) {
          data = {
            ...data,
            startDate: editActivityForm.value.startDate,
            endDate: editActivityForm.value.endDate,
          };
        }

        // Check if property type exist and pass just value
        if (changedData.value.type) {
          return {
            ...data,
            type: changedData.value.type.value
          };
        }

        return data;
      });

      const disabledAction = computed<boolean>(() => !Object.keys(changedData.value).length);

      function isDateChanged() {
        const dateKaysList = ['startDate', 'endDate'];
        const changedDataKeys = Object.keys(changedData.value);
        let result = false;

        dateKaysList.forEach((el: string) => {
          if (changedDataKeys.includes(el)) {
            result = true;
          }
        });

        return result;
      }

      async function onUpdate() {
        vuex.setLoading(true);

        return activitiesService.updateActivity(route.query.id as string, dataForUpdate.value)
          .then(() => {
            disabledHandleChanges.value = true;
            toast.success('Activity has been updated');
            router.push({ name: routesNames.adminActivities });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function fetchInitData() {
        if (!route.query.id) {
          router.push({ name: routesNames.adminActivities });
        } else {
          await fetchActivity();
        }
      }

      function setActivityForms(activity: IActivity) {
        editActivityForm.value = {
          ...activity,
          type: { value: activity.type, label: capitalizeFirstLetter(activity.type) }
        };
        originalActivity.value = { ...editActivityForm.value };
      }

      async function fetchActivity() {
        vuex.setLoading(true);

        return activitiesService.fetchActivity(route.query.id as string, ACTIVITY_PARAMS)
          .then((activity: IActivity) => setActivityForms(activity))
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            router.push({ name: routesNames.adminActivities });
          })
          .finally(() => vuex.setLoading(false));
      }

      async function onExport() {
        vuex.setLoading(true);

        return activitiesService.downloadAttendees(route.query.id as string)
          .then((res: any) => {
            downloadHelper(res, originalActivity.value.name as string, 'csv');
          } )
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(() => { fetchInitData(); });

      return {
        typesList,
        editActivityForm,
        disabledHandleChanges,

        disabledAction,

        onUpdate,
        onExport
      };
    }

  });
</script>